
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Loader',
  props: {
    showLoader: {
      type: Boolean,
      default: false,
      required: true,
    },
    styles: {
      type: String,
      default: '',
      required: false,
    },
  },
});
